// Default
.checkboxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;

    &.error {
        border: 2px solid #e60f46;
        justify-content: space-between;

        .checkbox {
            margin-right: 0;
        }
    }

    .checkbox {
        display: inline-flex;
        height: 3.125rem;
        line-height: 3.125rem;
        margin-bottom: 1rem;
        padding: 0 .5rem;
        background: #f2f2f2;

        label {
            display: block;
            position: relative;
            width: inherit;
            height: inherit;
            padding: 0 1.5rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: var(--BaseColor);
            cursor: pointer;

            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;

                &:checked ~ .checkmark .check {
                    display: block;
                }

                &:checked ~ .checkmark:after {
                    display: block;
                }
            }

            .checkmark {
                position: absolute;
                top: calc(50% - .875rem / 2);
                left: 0;
                height: 0.875rem;
                width: 0.875rem;
                border: 2px solid #11388d;

                .check {
                    display: none;
                    position: absolute;
                    bottom: -1px;
                }
            }
        }
    }
}

// > Mobile
@media screen and (min-width: 50rem) {
    .checkboxes {
        justify-content: left;

        .checkbox {
            width: 11.25rem;
            margin-right: 1.5rem;

            &:nth-last-of-type(6n) {
                margin-right: 0;
            }

            label {
                &:hover {
                    overflow: visible;
                    white-space: normal;
                    width: auto;
                    position: absolute;
                }

                &:hover input ~ .checkmark {
                    background-color: #ccc;
                }
            }
        }
    }
}
