/*
 * ELEMENT DETAIL VIEW
 */
#template-detail {
  text-align: left;
}
.template-logos {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.template-logos .template-logo {
  flex: 0 0 calc(50% - 0.5rem);
  position: relative;
  margin-top: 1rem;
  padding: 1rem;
  box-sizing: border-box;
  border: 1px solid #333;
}
.template-logos .template-logo img {
  max-width: 100%;
}
.template-logos .template-logo a.delete-image {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 0;
  left: 0;
  background: #e60f46;
  color: #fff;
  text-align: center;
  line-height: 1.5rem;
}

/*
 * MEDIA QUERIES > MOBILE
 */
@media all and (min-width: 30rem) {
  #upload-container {
    padding: 0;
  }
  .template-logos {
    justify-content: space-between;
  }
  .template-logos .template-logo {
    flex: 0 0 calc(33% - 0.5rem);
  }
}
