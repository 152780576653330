// Imports
@import "fonts";

// Defaults
main {
    width: 100%;
    color: var(--BaseColor);

    .main-content {

        .country-select-form {
            width: 100%;
            padding: .5rem 0;

            .country-select {
                display: block;
                width: inherit;
                overflow: hidden;
                position: relative;

                #mobile-country-select {
                    width: inherit;
                    padding: .5rem;
                    line-height: 2rem;
                    border: 0;
                    background-color: #11388d;
                    color: #fff;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                }

                i {
                    position: absolute;
                    top: 1rem;
                    right: 1rem;
                    color: #ffffff;
                }
            }
        }
    }
}

// > Mobile
@media screen and (min-width: 50rem) {
    main {
        .main-content {
            max-width: 1200px;
            margin: 0 auto;
        }
    }
}

