// Default
.radios {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .radio {
        display: inline-flex;
        width: 100%;
        height: 11.125rem;
        margin-bottom: 1.5rem;
        background: #f2f2f2;
        text-align: center;

        &:hover {
            background-color: #11388d;

            label .index, label .title {
                color: #fff;
            }
        }

        &:nth-of-type(3n) {
            margin-right: 0;
        }

        label {
            width: 100%;
            margin: 0 auto;
            position: relative;
            padding: 1.125rem 4.375rem;
            cursor: pointer;

            &.checked {
                background: #11388d;
                color: #fff;

                .index, .title {
                    color: #fff;
                }
            }

            input {
                display: none;
            }

            .index {
                position: absolute;
                top: calc(50% - 3.125rem / 2);
                left: 1.5rem;
                line-height: 3.75rem;
                color: #11388d;
            }

            .title {
                color: #11388d;
            }

            img {
                max-width: 100%;
                max-height: calc(100% - 2.25rem);
                margin: 0.5rem auto 0;
                display: block;
                background-color: #fff;
            }
        }
    }
}

// > Mobile
@media screen and (min-width: 50rem) {
    .radios {
        .radio {
            width: 23.125rem;
            margin-right: 2.75rem;
            margin-bottom: 2.5rem;
        }
    }
}
