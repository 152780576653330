#loader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: #001f47;
  opacity: .5;

  img {
    width: 4rem;
    height: 4rem;
    display: block;
    opacity: 1;
    margin: calc(50vh - 4rem) auto;
  }
}
