.batch-upload {
  .error-message {
    margin:0 2.75rem 0 2rem;
    height: auto;
    background-color: red;
    align-items: center;
    justify-content: center;
    display: flex;
    ul {
      li {
        margin-left: 0;
      }
    }
  }

  .name {
    padding: 2.75rem 2.75rem 0 2rem;
  }

  .template-select, .country-select {
    height: 3.125rem;
    padding: 0 2.75rem 0 2rem;

    label {
      display: block;
      text-align: center;
      color: #999;

      select {
        width: 100%;
        height: 3.125rem;
        margin-top: .5rem;
        font-size: 1rem;
        border: 2px solid var(--LinkHoverColor);
        text-align: center;
        cursor: pointer;

        &:hover {
          background-color: var(--LinkHoverColor);
        }
      }
    }
  }

  #country-select {
    padding: 3.5rem 2.75rem 0 2rem;
  }

  .upload {
    text-align: center;
    padding: 1rem 2.75rem 0 2rem;

    .btn {
      cursor: pointer;
    }
  }

  .validate {
    text-align: center;
    padding: 2rem 2.75rem 0 2rem;
    --BaseColor: #1eb90e;

    .batch-validate-button {
      color: black;
      background-color: var(--HeadLinkColor);
      padding: 0.5rem 1rem;
      border: 2px solid black;

      &:hover {
        background-color: var(--LinkHoverColor);
      }
    }
  }

  .file-upload-filename {
    display: block;
    text-align: center;
    padding-top: 5rem;
  }
}
