// Default
.pagination {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: max-content;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
    background: #f2f2f2;

    .left {
        margin: .5rem 0;

        span {
            color: #999;
        }
    }

    .right {
        margin: .5rem 0;

        select {
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 12.5rem;
            height: 1.875rem;
            border: 2px solid var(--LinkHoverColor);
            padding-left: 2.875rem;
            color: var(--LinkHoverColor);
            text-transform: uppercase;
            font-size: .75rem;
            cursor: pointer;

            background:
                    linear-gradient(45deg, transparent 50%, var(--LinkHoverColor) 50%),
                    linear-gradient(135deg, var(--LinkHoverColor) 50%, transparent 50%);

            background-position:
                    1rem calc((1.875rem / 2) - (0.625rem / 2)),
                    1.5rem calc((1.875rem / 2) - (0.625rem / 2)),
                    100% 0;
            background-size:
                    0.5rem 0.5rem,
                    0.5rem 0.5rem;
            background-repeat: no-repeat;

            &::-ms-expand {
                display: none;
            }
        }
    }

    .center {
        display: flex;
        justify-content: center;
        margin: .5rem 0;

        a, span {
            width: 1.875rem;
            height: 1.875rem;
            line-height: calc(1.875rem - 2px);
            margin: 0 0.313rem;
            text-align: center;
            text-decoration: none;
            transition: background-color .3s;
            border: 2px solid var(--LinkHoverColor);
            color: var(--LinkHoverColor);
        }

        a {
            &.active {
                background-color: var(--BaseColor);
                color: var(--LinkHoverColor);
            }

            &:hover:not(.active) {
                background-color: var(--BaseColor);
            }
        }

        .disabled {
            cursor: not-allowed;
            background: #efefef;
        }
    }
}

// > Mobile
@media screen and (min-width: 50rem) {
    .pagination {
        flex-direction: row;
        height: 5rem;
        margin: 3.375rem 0;

        .left {
            flex-basis: 25%;
            margin-left: 2.5rem;
            text-align: left;
        }

        .center {
            flex-basis: 50%;

            a, span {
                &.first, &.last {
                    width: 3.75rem;
                    margin: 0 0.625rem;
                }
            }
        }

        .right {
            flex-basis: 25%;
            margin-right: 2.5rem;
            text-align: right;
        }
    }
}
