/*
 * BUTTONS
 */
.wizard-btn {
  background: var(--BaseColor) !important;
  color: var(--LinkHoverColor) !important;
  border: 2px solid var(--LinkHoverColor) !important;

  &:hover {
    background: var(--LinkHoverColor) !important;
    color: var(--BaseColor) !important;
    border: 2px solid var(--BaseColor) !important;
  }
}

.btn {
  display: inline-flex;
  height: 1.75rem;
  margin: 1rem 0;
  padding: 0 1rem;
  line-height: calc(1.75rem - 2px);
  font-size: .9rem;
  background: var(--BaseColor);
  color: var(--LinkHoverColor);
  border: 2px solid var(--LinkHoverColor);
  text-align: center;
  font-family: 'Arial', 'sans-serif';

  &:hover {
    background: var(--LinkHoverColor);
    color: var(--BaseColor);
    border: 2px solid var(--BaseColor);
  }
  &:focus, &:active {
    background: var(--BaseColor);
    color: var(--LinkHoverColor);
    filter: brightness(90%);
  }

  &.update-btn {
    margin-right: 1.875rem;
  }

  // Login Button
  &.btn-lg {
    font-size: 1.8rem;
    height: 2.2rem;
  }

  &.btn-blue {
    background: #11388d;
    color: white;

    &:hover, &:focus, &:active {
      background: #11388d;
      filter: brightness(90%);
    }
  }

  i {
    line-height: calc(1.75rem - 2px);
    padding-right: .5rem;
  }
}
