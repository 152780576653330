// Default
.upload-container {
    .upload-drop {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 6rem;
        border: 3px dashed var(--LinkHoverColor);
        color: var(--BaseColor);
        margin: .5rem 1rem;
        cursor: pointer;

        .mobile {
            display: none;
        }

        &:active {
            opacity: .66;
            background: var(--LinkHoverColor);
            color: var(--BaseColor);
            border: 3px dashed #ffffff;
        }

        &.file-over {
            opacity: .66;
            background-color: var(--LinkHoverColor);
            color: var(--BaseColor);
            border-color: white;
        }

        &.error {
            border: 3px dashed #e60f46;
        }
    }

    h3 {
        text-align: center;
        color: #999;
        margin: 1.875rem 0 0.875rem 0;
    }

    #template-preview {
        width: 100%;
        border: 2px solid #ddd;
        box-sizing: content-box;
    }

    .images {
        display: flex;
        flex-flow: row wrap;

        .image {
            position: relative;
            margin: .5rem 1rem;
            padding: 1rem;
            background-color: #eeeeee;

            img {
                display: block;
                max-width: 100%;
                max-height: 100%;
                margin: 0 auto;
            }

            .delete-image {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 1.75rem;
                height: 1.75rem;
                position: absolute;
                top: .25rem;
                right: .25rem;
                background: #11388d;
                color: #fff;
                border-radius: 1rem;
                text-decoration: none;
            }
        }
    }
}

// > Mobile
@media screen and (min-width: 50rem) {
    .upload-container {
        padding: 1rem;

        .upload-drop {
            width: 100%;
            height: 12.125rem;
            margin: 0;
        }

        #template-preview {
            width: 100%;
            max-width: 1200px;
        }

        .images {
            .image {
                flex-basis: 20%;
            }
        }
    }
}

.images-info {
    margin: 1rem 0 0;
    text-align: center;
}
