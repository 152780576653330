.batch-basket {
  .batch-order {
    text-align: center;
    padding: 2rem 2.75rem 2rem 2rem;
    --BaseColor: #1eb90e;

    .batch-order-button {
      color: black;
      background-color: var(--HeadLinkColor);
      padding: 0.5rem 1rem;
      border: 2px solid black;

      &:hover {
        background-color: var(--LinkHoverColor);
      }
    }
  }
  .batch-basket-back {
    margin-bottom: 1rem;
    margin-left: 2rem;

    .batch-basket-back-button {
      color: var(--LinkHoverColor);
      background-color: black;
      padding: 0.5rem 1rem;
      border: 2px var(--LinkHoverColor);

      &:hover {
        color: black;
        background-color: var(--LinkHoverColor);
        border: 2px black;
      }
    }
  }
}
