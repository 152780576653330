.header-content {
  position: fixed;
  display: flex;
  background: var(--BaseColor);
  background: linear-gradient(
                  89deg,
                  var(--BaseColor) 0%,
                  var(--SecondaryColor) 100%
  );
  width: 100vw;
  z-index: 1337;
}

.header-content__logo {
  margin-left: 1vw;
  max-height: 100%;
}
