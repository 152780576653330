// Imports
@import "fonts";

// Default
header {
    width: 100%;
    height: 9rem;
    position: fixed;
    background-color: var(--SecondaryColor);
    z-index: 999;

    .header-content {
        height: inherit;
        display: flex;
        flex-direction: column;
        color: var(--HeadFontColor);
        text-align: center;

        #branding {
            height: 6.5rem;

            img {
                height: 3.5rem;
                padding: .25rem;
            }

            h1 {
                height: 2.5rem;
                line-height: 2.5rem;
                margin: 0;
                padding: .25rem .5rem 0;
                text-transform: uppercase;
                font-size: 1.5rem;
            }
        }

        nav {
            height: 2.5rem;

            ul {
                margin: 0;
                padding: 0;
                list-style-type: none;
                display: flex;
                flex-direction: row;

                li {
                    width: auto;
                    .dropdown {
                        height: 2.5rem;
                        line-height: 2.5rem;

                        button {
                            background:none;
                            border:none;
                            outline:none;
                            cursor: pointer;
                            border-bottom: 2px solid var(--HeadLinkColor);
                            color: var(--HeadLinkColor);
                            font-size: 1.125rem;
                            padding:0;
                        }

                        .dropdown-content {
                            border-radius: 25px;
                            display: none;
                            position: absolute;
                            min-width: 160px;
                            box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
                            z-index: 1;
                            background: linear-gradient(
                                            89deg,
                                            var(--BaseColor) 0%,
                                            var(--SecondaryColor) 100%
                            );                            a {
                                text-decoration: none;
                                display: block;
                            }
                        }
                    }
                    .dropdown:hover, .dropdown:active {
                        color: var(--LinkHoverColor);
                        .dropdown-content {
                            display: block;
                        }
                        button {
                            color: var(--LinkHoverColor);
                        }
                    }
                    a {
                        height: 2.5rem;
                        line-height: 2.5rem;
                        border-bottom: 2px solid var(--HeadLinkColor);
                        margin: 1rem;
                        color: var(--HeadLinkColor);

                        &:hover, &active {
                            color: var(--LinkHoverColor);
                            border-color: var(--LinkHoverColor);
                        }
                    }
                }
            }
        }

        a {
            color: var(--HeadLinkColor);
            text-decoration: none;

            &.active {
                color: var(--LinkHoverColor);
                border-color: var(--LinkHoverColor);
            }
        }
    }
}
.header-spacer {
    height: 9rem;
}

// > Mobile
@media screen and (min-width: 50rem) {
    header {
        height: 8.5rem;

        .header-content {
            flex-direction: row;

            #branding {
                display: flex;
                flex-direction: row;
                flex-basis: 40%;
                height: inherit;
                align-items: center;

                img {
                    padding: 0;
                }

                h1 {
                    margin: .5rem 0 0 .5rem;
                    padding: 0;
                    font-size: 1.25rem;
                    color: #ffffff;
                }
            }

            nav {
                display: flex;
                flex-direction: row;
                flex-basis: 60%;
                height: inherit;
                align-items: center;

                ul {
                    flex-basis: 20%;
                    text-align: center;

                    li {
                        font-size: 1.125rem;
                    }
                }

                .element-actions {
                    display: flex;
                    flex-direction: row;
                    flex-basis: 80%;
                    justify-content: right;

                    .new-element {
                        display: inline-flex;
                        height: 1.75rem;
                        margin: 1rem 0;
                        padding: 0 1rem;
                        line-height: calc(1.75rem - 2px);
                        font-size: .9rem;
                        border: 1px solid #fff;
                        background: var(--SecondaryColor);

                        &:hover, &:active {
                            filter: brightness(90%);
                        }

                        i {
                            padding-right: .5rem;
                            line-height: inherit;
                        }
                    }

                    .element-search {
                        flex-basis: 50%;
                        position: relative;

                        input {
                            display: inline-flex;
                            height: 1.75rem;
                            margin: 1rem;
                            width: 94%;
                            padding: 0 1rem;
                            line-height: calc(1.75rem - 2px);
                            font-size: .9rem;
                            border: 1px solid #fff;
                            background: var(--SecondaryColor);
                            color: var(--HeadFontColor);
                            text-align: center;

                            &:hover, &:active, &:focus {
                                filter: brightness(90%);
                            }

                            &::placeholder {
                                opacity: 1;
                            }
                        }

                        .search-remove-btn {
                            width: 2rem;
                            height: 2rem;
                            line-height: 2rem;
                            position: absolute;
                            top: calc(50% - 1rem);
                            right: 0;
                            color: #fff;
                            font-size: 1.5rem;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    .header-spacer {
        height: 8.5rem;
    }
}
