// Imports
@import "fonts";

// Default
.messages {
    width: 100%;
    height: 4rem;
    top: 5rem;
    position: fixed;
    color: #fff;
    line-height: 4rem;
    font-size: 1.25rem;
    text-align: center;
    transition: top 1s ease-in;
    z-index: 10;
    overflow: hidden;

    &.show {
        top: 9rem;
        transition: top 1s ease-out;
    }
    &.success {
        background: #4bb543;
    }
    &.info {
        background: orange;
    }
    &.error {
        background: red;
    }
}

// > Mobile
@media screen and (min-width: 50rem) {
    .messages {
        top: 4.5rem;

        &.show {
            top: 8.5rem;
        }
    }
}
