// Imports
@import "fonts";

// Default
footer {
    width: 100vw;
    position: fixed;
    bottom: 0;
    padding: .5rem;

    .footer-content {
        .element-actions {
            display: flex;
            flex-direction: column;
            justify-content: right;
            align-items: center;
            position: relative;

            // Element Search
            .element-search-btn {
                display: block;
                width: 3rem;
                height: 3rem;
                position: absolute;
                right: 0;
                bottom: 10rem;
                line-height: 3rem;
                text-align: center;
                background: #11388d;
                color: #fff;
                border-radius: 2rem;

                input {
                    display: none;
                }
            }

            .element-search {
                position: absolute;
                right: .5rem;
                bottom: 10rem;

                input {
                    display: inline-flex;
                    width: 0;
                    height: 3rem;
                    padding: 0 1rem;
                    line-height: calc(1.75rem - 2px);
                    font-size: .9rem;
                    border: none;
                    border-radius: 2rem;
                    background: var(--SecondaryColor);
                    color: var(--HeadFontColor);
                    text-align: center;
                    transition: width .5s ease-in;

                    &:hover, &:active, &:focus {
                        filter: brightness(90%);
                        outline: none;
                    }

                    &::placeholder {
                        opacity: 1;
                    }
                }
            }

            // New Element
            .new-element {
                display: block;
                width: 3rem;
                height: 3rem;
                position: absolute;
                right: 0;
                bottom: 6.5rem;
                line-height: 3rem;
                text-align: center;
                background: #11388d;
                color: #fff;
                border-radius: 2rem;
            }
        }
    }
}

// > Mobile
