// Imports
@import "fonts";

// Default
.element-list {
    width: 100%;
    padding: .5rem;

    .table {
        width: 100%;
        text-align: center;

        .thead {
            display: none;
        }

        .tbody {
            .tr {
                display: block;
                padding: 1rem;
                background-color: #eeeeee;
                text-decoration: none;
                color: var(--BaseColor);
                margin-bottom: 1rem;

                .td {
                    line-height: 1.5rem;

                    img {
                        max-width: 33%;
                    }

                    .btn.action {
                        display: inline-flex;
                        height: 1.75rem;
                        margin: 1rem 0;
                        padding: 0 1rem;
                        line-height: calc(1.75rem - 2px);
                        font-size: .9rem;
                        background: #fff;
                        color: #11388d;
                        border: 2px solid #11388d;
                        text-align: center;
                        text-decoration: none;
                        cursor: pointer;

                        &:hover, &:active {
                            background: #11388d;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}

// > Mobile
@media screen and (min-width: 50rem) {
    .element-list {
        margin-top: 2rem;
        padding: 0;

        .table {
            display: table;

            .thead {
                display: table-header-group;
                background-color: #eeeeee;
                text-align: left;

                .tr {
                    display: table-row;

                    .th {
                        display: table-cell;
                        height: 5rem;
                        line-height: 5rem;
                        padding-left: 1rem;
                        font-size: 1.25rem;
                        color: #999999;

                        &.th-country-select {
                            position: relative;

                            #desktop-country-select-form {
                                width: 12rem;
                                position: absolute;
                                top: 0;
                                right: 2.75rem;
                                padding: 0;

                                label {
                                    font-size: 1rem;

                                    select {
                                        width: inherit;
                                        padding: .25rem;
                                        background-color: #11388d;
                                        color: #fff;
                                        -webkit-appearance: none;
                                        -moz-appearance: none;
                                        appearance: none;
                                    }

                                    i {
                                        position: absolute;
                                        top: 2rem;
                                        right: 1rem;
                                        color: #ffffff;
                                    }
                                }
                            }
                        }

                        &:first-of-type {
                            padding-left: 2.75rem;
                        }
                        &:last-of-type {
                            padding-right: 2.75rem;
                        }

                        a {
                            color: #e60f46;
                            text-decoration: none;
                        }

                        i.sort-icon {
                            color: #e60f46;
                        }
                    }
                }
            }

            .tbody {
                display: table-row-group;
                text-align: left;

                .tr {
                    display: table-row;

                    &:hover > .td {
                        background-color: #dddddd;
                        cursor: pointer;
                    }

                    .td {
                        display: table-cell;
                        padding: 1rem;
                        background-color: white;
                        border-bottom: 3px solid #f2f2f2;
                        vertical-align: middle;

                        &:first-of-type {
                            padding-left: 2.75rem;
                        }
                        &:last-of-type {
                            padding-right: 2.75rem;
                        }

                        .btn.action {
                            margin: 1rem 1rem 1rem 0;
                        }

                        img {
                            max-height: 3rem;
                            max-width: initial;
                        }
                    }
                }
            }
        }
    }
}
