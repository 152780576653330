// Default
#template-wizard, #menu-card-wizard {
    margin-top: 3rem;
    padding: 1rem;

    .vue-form-wizard {
        position: relative;
    }

    .wizard-tab-content {
        margin-top: 2rem;
        padding: 2rem 0;
    }

    .wizard-progress-with-circle {
        width: 100%;
        margin: 0 auto;
        height: 2px;

        .wizard-progress-bar {
            background-color: #999 !important;
            width: 100% !important;
        }
    }

    .wizard-nav {
        width: 100%;
        margin: 0 auto;
        justify-content: space-between;
    }

    .wizard-card-footer {
        position: absolute;
        top: -3rem;
        width: 100%;

        .wizard-footer-left {
            position: inherit;
            left: 0;
            float: none;
            background-color: #11388d !important;
            background: #11388d;
        }

        .wizard-footer-right {
            position: inherit;
            right: 0;
            float: none;
            background-color: #11388d !important;
            background: #11388d;
        }
    }

    .wizard-progress-with-circle {
        top: 1.125rem;
    }

    /*
    .wizard-nav li {
      background: linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 0%);
    }
     */

    .wizard-nav li {
        flex: 0;
    }

    .wizard-nav li, .wizard-icon-container, [role="tab"] {
        width: 1.875rem;
        height: 1.875rem;
        padding: 0;
        margin: 0;

        a {
            display: block;
            width: inherit;
            height: inherit;
            color: white;
            top: 0;
        }

        a div {
            display: none;
        }

        .wizard-icon-circle.checked.completed {
            + span {
                background-color: green;
                color: white;
            }
        }

        a span {
            display: block;
            width: inherit;
            height: inherit;
            line-height: 1.875rem;
            background-color: #f2f2f2;
            color: #999999;

            &.active {
                background-color: black !important;
                color: white !important;
            }
        }

        .tab_shape {
            width: inherit;
            min-width: 0;
            background-color: white;
        }

        .wizard-icon {
            display: none;
        }
    }
}

// > Mobile
@media screen and (min-width: 50rem) {
    #template-wizard, #menu-card-wizard {
        margin-top: 2rem;
        padding: 0;

        .wizard-progress-with-circle {
            width: 23.675rem;
        }

        .wizard-nav {
            width: 23.625rem;
        }

        .wizard-card-footer {
            position: absolute;
            top: 0;
        }
    }
}

.wizard-header {
    display: none;
}

.name, .customer, .template, .placeholder {
    width: 100%;

    label {
        width: 100%;
        display: block;
        text-align: center;
        color: #999;

        input, select {
            width: 100%;
            height: 3.125rem;
            margin-top: .5rem;
            padding: .3125rem;
            line-height: 3.125rem;
            font-size: 1rem;
            border: 2px solid var(--LinkHoverColor);
            color: var(--BaseColor);
            text-align: center;

            &.error {
                border: 2px solid #e60f46;
            }
        }

        select {
            border-radius: 0;
            box-shadow: none;
        }

        .search.selection.dropdown {
            width: 100%;
            height: 3.125rem;
            margin-top: .5rem;
            padding: 0;
            line-height: 3.125rem;
            font-size: 1rem;
            border: 2px solid #11388d;
            color: #11388d;
            text-align: center;

            .search {
                width: 100%;
                height: 3.125rem;
                margin-top: 0;
                padding: 0;
                line-height: 3.125rem;
                font-size: 1rem;
                color: #11388d;
                text-align: center;
            }
        }
    }
}

.errors {
    padding: 2rem;
    background: #e60f46;
    color: #fff;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

#template-files {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .template-file {
        flex-basis: 25%;
    }
}

.wizard-step-info {
    position: absolute;
    width: 100%;
    top: 2.5rem;
    text-align: center;

    h3 {
        margin: 0 0 1.75rem 0;
        font-family: Bull Medium, Arial, 'sans-serif';
    }
}

#customer-search {
    cursor: pointer;
}

.filtered-customers {
    max-height: 30rem;
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    border: 2px solid var(--LinkHoverColor);
    border-top: none;
    overflow-y: scroll;

    .country, .city, .customer {
        height: 3.125rem;
        padding: 0.3125rem;
        line-height: calc(3.125rem - 0.3125rem);
        font-size: 1rem;
        border-bottom: 1px solid #333;
        text-align: center;
        cursor: pointer;
        background-color: lightgray;
        color: #333;
    }

    .city {
        height: 2.5rem;
        line-height: calc(2.5rem - 0.3125rem);
        background-color: #f0f0f0;
    }

    .customer {
        border-bottom: 1px solid var(--LinkHoverColor);
        background-color: var(--HeadFontColor);
        color: var(--BaseColor);
        font-weight: bold;

        &:hover {
            background-color: var(--LinkHoverColor);
            color: var(--BaseColor);
        }
    }
}
